import { createElement as _createElement } from "react";
import { jsx as _jsx } from "react/jsx-runtime";
import { Autocomplete, Avatar, Chip, TextField as MuiTextField, Skeleton, Typography, createFilterOptions } from '@mui/material';
import { CheckIconContainer, optionStyle, sxAvatar } from './CheckboxAutocomplete.styled';
import { useController } from 'react-hook-form';
import { useCallback, useMemo, useRef, useState } from 'react';
import { CheckIcon } from './CheckIcon';
import { Checkbox } from 'core/ui';
import { SelectAllPaper } from './SelectAllPaper';
import { t } from 'shared/translations';
/**
 * Autocomplete with checkboxes and one SelectAll checkbox that checks or unchecks only filtered options.
 * @param param0
 */
const CheckboxAutocomplete = ({ options, keyField, labelField, iconField, chipColor = 'primary', limitTags, checkboxStyle = 'default', ...controllerProps }) => {
    const [selectAll, setSelectAll] = useState(false);
    // Used so store input value after option is selected (Mui resets input value by default). Required for filter and 'select all'.
    const [inputValue, setInputValue] = useState('');
    // Using useState instead of useRef here forces infinite rerenders on setFilterOptions.
    const filteredOptionIds = useRef([]);
    const { field: { value, onChange, ...field }, fieldState: { invalid, error }, } = useController(controllerProps);
    const onSelectAllCheckboxClick = useCallback(() => {
        setSelectAll((previousSelectAll) => {
            if (previousSelectAll) {
                onChange(value.filter((id) => !filteredOptionIds.current.some((optionId) => optionId === id)));
            }
            else {
                const idsToAdd = filteredOptionIds.current.filter((optionId) => !(value ?? []).some((id) => id === optionId));
                onChange([...(value ?? []), ...idsToAdd]);
            }
            return !previousSelectAll;
        });
    }, [value, filteredOptionIds, onChange, setSelectAll]);
    // TODO: check later if it works without useMemo, since it is MUI5 bug: https://github.com/mui/material-ui/issues/31073.
    const selectAllPaper = useMemo(() => (paperProps) => {
        return (_jsx(SelectAllPaper, { paperProps: paperProps, chipColor: chipColor, selectAll: selectAll, nothingFound: !filteredOptionIds.current.length, onSelectAllCheckboxClick: onSelectAllCheckboxClick }));
    }, [chipColor, selectAll, options, filteredOptionIds.current, onSelectAllCheckboxClick]);
    // useMemo inside MuiAutocomplete recommended by MUI when value is controlled.
    const selectedValues = useMemo(() => (value && options ? options.filter((option) => value.includes(option[keyField])) : []), [keyField, options, value]);
    const filter = createFilterOptions();
    if (!options)
        return (_jsx(Skeleton, { children: _jsx(MuiTextField, { size: "small" }) }));
    return (_jsx(Autocomplete, { multiple: true, disableCloseOnSelect: true, ...field, limitTags: limitTags, 
        //value={options.filter((option) => value.includes(option[keyField]))}
        value: selectedValues, onChange: (event, newValue, reason) => {
            const selectedIds = newValue.map((item) => item[keyField]);
            if (reason === 'clear' || reason === 'removeOption')
                setSelectAll(false);
            // if (reason === 'selectOption' && value.length === options.length) setSelectAll(true); // For select all, not only filtered.
            // if (reason === 'selectOption' && filteredOptionIds.current.every((optionId) => value.some((option) => option[keyField] === optionId)))
            // 	setSelectAll(true);
            if (reason === 'selectOption' && filteredOptionIds.current.every((optionId) => selectedIds.includes(optionId))) {
                setSelectAll(true);
            }
            // onChange(value.map((item) => item[keyField]));
            onChange(selectedIds);
        }, options: options ?? [], getOptionLabel: (option) => option[labelField], 
        // groupBy={(option) => option.city}
        defaultValue: [], noOptionsText: _jsx(Typography, { textAlign: "center", children: t('nothing.found.label') }), renderInput: (params) => _jsx(MuiTextField, { ...params, size: "small", error: Boolean(error), label: "", placeholder: "" }), renderTags: (value, getTagProps, ownerState) => {
            return value?.map((option, index) => {
                // Key value should be passed within `key` prop only. When it used through spread operator it leads to react console error.
                const { key, ...tagProps } = getTagProps({ index });
                return _jsx(Chip, { variant: "outlined", size: "small", color: chipColor, label: option[labelField], ...tagProps }, key);
            });
        }, renderOption: (props, option, { selected }) => (_createElement("li", { ...props, key: option[keyField], style: optionStyle },
            checkboxStyle === 'default' && _jsx(Checkbox, { checked: selected, sx: { marginRight: '.5rem' } }),
            iconField && _jsx(Avatar, { alt: option[labelField], src: `data:image/jpeg;base64, ${option[iconField]}`, sx: sxAvatar }),
            option[labelField],
            checkboxStyle === 'iconEnd' && selected && (_jsx(CheckIconContainer, { children: _jsx(CheckIcon, {}) })))), 
        //open={true} // Uncomment to debug custom paper styles.
        PaperComponent: selectAllPaper, onInputChange: (_, value, reason) => reason !== 'reset' && setInputValue(value), inputValue: inputValue, filterOptions: (options, state) => {
            const filtered = filter(options, state);
            filteredOptionIds.current = filtered.map((option) => option[keyField]);
            //setSelectAll(filteredOptionIds.current.every((optionId) => (value as number[]).includes(optionId)));
            const allFilteredAreChecked = filteredOptionIds.current.every((optionId) => value.includes(optionId));
            if (selectAll !== allFilteredAreChecked) {
                // setTimeout is used to prevent console error `Cannot update a component while rendering a different component`
                setTimeout(() => {
                    setSelectAll(allFilteredAreChecked);
                }, 10);
            }
            return filtered;
        } }));
};
export { CheckboxAutocomplete };
