import { jsx as _jsx } from "react/jsx-runtime";
import { MuiButtonStyled } from './Button.styled';
/**
 * **Global Parts** `Button` component.
 *
 * Additional properties: `floatRight`.
 */
const Button = (props) => {
    return _jsx(MuiButtonStyled, { ...props });
};
export { Button };
