import { baseApiQuery } from 'shared/helpers/api';
import { createApi } from '@reduxjs/toolkit/query/react';
import { showApiErrorSnackbar } from 'shared/helpers/thunk';
import { t } from 'shared/translations';
export const storageApi = createApi({
    reducerPath: 'api/settings/storage',
    tagTypes: ['Storage'],
    baseQuery: baseApiQuery,
    endpoints: (builder) => ({
        getStorage: builder.query({
            query: () => ({
                method: 'GET',
                url: 'cellSettings/storageSector',
            }),
            //transformResponse: (response: { 1: IStockListItem[]; 2: IStockListItem[] }) => ({ stock: response[1], transfer: response[2] }),
            providesTags: ['Storage'],
        }),
        getStorageSector: builder.query({
            query: (sectorName) => ({
                method: 'GET',
                url: `cellSettings/storageSector/${sectorName}`,
            }),
            providesTags: ['Storage'],
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('get.storage.sector.request.failed'), error }));
                }
            },
        }),
        getOccupiedSectorNames: builder.query({
            query: () => ({
                method: 'GET',
                url: `cellSettings/cell-sectors`,
            }),
            providesTags: ['Storage'],
        }),
        updateStorageSector: builder.mutation({
            query: (body) => ({
                method: 'POST',
                url: `cellSettings/addStorageCell`,
                body,
            }),
            invalidatesTags: (result, error, storageSectors) => [{ type: 'Storage' }],
        }),
        deleteStorageSector: builder.mutation({
            query: (body) => ({
                method: 'DELETE',
                url: `cellSettings/deleteStorage`,
                body,
            }),
            invalidatesTags: ['Storage'],
        }),
    }),
});
export const { useGetStorageQuery, useGetStorageSectorQuery, useGetOccupiedSectorNamesQuery, useUpdateStorageSectorMutation, useDeleteStorageSectorMutation, } = storageApi;
