import { showApiErrorSnackbar, showApiSuccessSnackbar } from 'shared/helpers/thunk';
import { baseApiQuery } from 'shared/helpers/api';
import { createApi } from '@reduxjs/toolkit/query/react';
import { t } from 'shared/translations';
export const identityApi = createApi({
    reducerPath: 'api/identity',
    tagTypes: ['Roles', 'Users'],
    baseQuery: baseApiQuery,
    endpoints: (builder) => ({
        getUsers: builder.query({
            query: () => ({
                method: 'GET',
                url: 'user/userList',
            }),
            transformResponse: (response) => response.map((user) => ({ ...user, created: new Date(user.created), birthday: user.birthday ? new Date(user.birthday) : null })),
            providesTags: ['Users'],
        }),
        getUser: builder.query({
            query: ({ userId }) => ({
                method: 'GET',
                url: `user/getUser/${userId}`,
            }),
            transformResponse: (response) => ({
                ...response,
                created: new Date(response.created),
                birthday: response.birthday ? new Date(response.birthday) : null,
            }),
            providesTags: (result, error, { userId }) => [{ type: 'Users', userId }],
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('get.user.request.failed'), error }));
                }
            },
        }),
        editUser: builder.mutation({
            query: ({ user, file }) => {
                const formData = new FormData();
                // Does `null` mean removing existed photo?
                if (file || file === null) {
                    formData.append('file', file); // append doesn't receive null!
                    //user.changePhoto = true;
                    user.changePhoto = true;
                }
                formData.append('json', JSON.stringify(user));
                // data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD/4QBoRXhpZgAATU0AKgAAAAgA...
                return {
                    method: 'POST',
                    url: 'user/editUser',
                    body: formData,
                    formData: true,
                };
            },
            invalidatesTags: (result, error, { user }) => (!user.userId ? ['Users'] : [{ type: 'Users', userId: user.userId }]),
            onQueryStarted: async ({ user }, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;
                    dispatch(showApiSuccessSnackbar(user.userId ? t('user.updated.successfully') : t('user.added.successfully')));
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('user.update.failed'), error }));
                }
            },
        }),
        deleteUser: builder.mutation({
            query: ({ userId }) => ({
                method: 'DELETE',
                url: `user/deleteUser/${userId}`,
            }),
            invalidatesTags: (result, error, { userId }) => [{ type: 'Users', userId }],
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('delete.user.request.failed'), error }));
                }
            },
        }),
        deactivateUser: builder.mutation({
            query: ({ userEmail, minutes, userId }) => ({
                method: 'POST',
                url: 'identity/blockUser/',
                body: {
                    login: userEmail,
                    minutes: minutes ?? null,
                },
            }),
            // TODO: (when new backend is done) We need to activate user by id but not login, because we have to use additional unnecessary prop userId
            // here to update user separately and not to update all with invalidatesTags method.
            invalidatesTags: (result, error, { userId }) => [{ type: 'Users', userId }],
        }),
        activateUser: builder.mutation({
            query: ({ userEmail, userId }) => ({
                method: 'POST',
                url: 'identity/blockUser/',
                body: {
                    login: userEmail,
                    minutes: 0,
                },
            }),
            // TODO: read same for deactivateUser.invalidatesTags.
            invalidatesTags: (result, error, { userId }) => [{ type: 'Users', userId }],
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('activate.user.request.failed'), error }));
                }
            },
        }),
        resetUserPassword: builder.mutation({
            query: ({ userId }) => ({
                method: 'GET', // Why get?
                url: `user/sendChangedPassword/${userId}`,
            }),
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('reset.user.password.request.failed'), error }));
                }
            },
        }),
        getRoles: builder.query({
            query: () => ({
                method: 'GET',
                url: 'roles/getRoles',
            }),
            providesTags: ['Roles'], // TODO:
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('get.roles.request.failed'), error }));
                }
            },
        }),
        getRole: builder.query({
            query: ({ roleId }) => ({
                method: 'GET',
                url: `roles/getRoles/${roleId}`,
            }),
            transformResponse: (response) => response[0],
            providesTags: (result, error, { roleId }) => [{ type: 'Roles', roleId }],
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('get.role.request.failed'), error }));
                }
            },
        }),
        getClaims: builder.query({
            query: () => ({
                method: 'GET',
                url: 'roles/getRolesClaims',
            }),
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('get.claims.request.failed'), error }));
                }
            },
        }),
        editRole: builder.mutation({
            query: ({ role }) => ({
                method: 'POST',
                url: 'roles/addOrUpdateRole',
                body: role,
            }),
            invalidatesTags: (result, error, { role }) => [{ type: 'Roles', roleId: role.id }],
            onQueryStarted: async ({ role }, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: role.id ? t('edit.role.request.failed') : t('add.role.request.failed'), error }));
                }
            },
        }),
        deleteRole: builder.mutation({
            query: ({ roleId }) => ({
                method: 'DELETE',
                url: `roles/deleteRole/${roleId}`,
            }),
            invalidatesTags: (result, error, { roleId }) => [{ type: 'Roles', roleId }],
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('delete.role.request.failed'), error }));
                }
            },
        }),
        /** Gets users with CustomerManager claim in assigned roles. */
        getCustomerManagerLookup: builder.query({
            query: () => ({
                method: 'GET',
                url: 'roles/customer-managers',
            }),
            providesTags: ['Roles'],
        }),
        /** Gets users with SupplierManager claim in assigned roles. */
        getSupplierManagerLookup: builder.query({
            query: () => ({
                method: 'GET',
                url: 'roles/supplier-managers',
            }),
            providesTags: ['Roles'],
        }),
        getOwnDeliveryPoints: builder.query({
            query: () => ({
                method: 'GET',
                url: 'DeliveryPoint/getOwnDeliveryPoints',
            }),
        }),
    }),
});
export const { useGetUsersQuery, useGetUserQuery, useGetRolesQuery, useGetRoleQuery, useGetClaimsQuery, useEditUserMutation, useEditRoleMutation, useDeleteRoleMutation, useDeactivateUserMutation, useActivateUserMutation, useDeleteUserMutation, useGetOwnDeliveryPointsQuery, useResetUserPasswordMutation, useGetCustomerManagerLookupQuery, useGetSupplierManagerLookupQuery, } = identityApi;
