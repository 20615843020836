/**
 * Gets detail's *price* by *prime cost* and *margin percent*.
 *
 * @param primeCost
 * @param marginPercent
 * @returns
 */
export function getPriceByPrimeCostAndMargin(primeCost, marginPercent) {
    return primeCost * (marginPercent / 100) + primeCost;
}
