import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, FormControl, FormHelperText, FormLabel, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { defaultValues, schema } from './Login.yup';
import { Header, Paper, PaperContainer, RootBox } from './Login.styled';
import { getCurrentUser, useAuthStore, useLoginMutation } from 'shared/modules/auth/business';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { TextField } from 'core/ui';
import { enqueueSnackbar } from 'notistack';
import { getQueryErrorMessage } from 'core/utils/errorHandling';
import logo from 'shared/assets/gp-card-logo.png';
import { t } from 'shared/translations';
import { yupResolver } from '@hookform/resolvers/yup';
const getSimplifiedUserData = (token) => {
    // Need to get key "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid" -> sid.
    const result = Object.keys(token).map((key) => {
        const lastSlashIndex = key.lastIndexOf('/');
        const simplifiedKey = lastSlashIndex === -1 ? key : key.slice(lastSlashIndex + 1);
        const value = token[key];
        return { key: simplifiedKey, value };
    });
    return result;
};
const Login = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const authSuccess = useAuthStore((state) => state.authSuccess);
    const [login, { isLoading, isError, error, data }] = useLoginMutation();
    const { handleSubmit, control, formState: { errors }, } = useForm({
        resolver: yupResolver(schema),
        defaultValues: defaultValues,
    });
    const onSubmit = async (data) => {
        try {
            const loginResult = await login(data).unwrap();
            if (loginResult.error)
                return;
            const currentUser = await getCurrentUser(loginResult.token);
            authSuccess(loginResult.token, currentUser);
            // Navigate to the route originally being accessed.
            const redirectedFrom = location.state?.pathname || '/';
            navigate(redirectedFrom, { replace: true });
        }
        catch (error) {
            enqueueSnackbar({ variant: 'error', header: t('authorization.error'), body: t('see.console.for.more.info'), persist: true });
            console.error(`Login failed.`, error);
        }
    };
    const onInvalid = (data) => {
        console.log('Validation errors:', data);
    };
    return (_jsxs(RootBox, { children: [_jsx(Header, { children: _jsx("img", { src: logo, alt: "logo" }) }), _jsx(PaperContainer, { maxWidth: false, children: _jsxs(Paper, { elevation: 3, children: [_jsx(Typography, { variant: "h3", textAlign: "center", fontWeight: (theme) => theme.typography.fontWeightBold, mb: 3, mt: 3, children: "Sign in to your account" }), _jsx("form", { id: "loginForm", onSubmit: handleSubmit(onSubmit, onInvalid), children: _jsxs(Box, { display: "flex", flexDirection: "column", gap: 3, sx: { maxWidth: '24rem', width: '24rem', mt: '1rem' }, children: [_jsxs(FormControl, { fullWidth: true, error: Boolean(errors.email), disabled: isLoading, children: [_jsx(FormLabel, { children: "Email" }), _jsx(Controller, { name: "email", control: control, rules: { required: true }, render: ({ field }) => _jsx(TextField, { fullWidth: true, ...field }) }), _jsx(FormHelperText, { children: errors.email?.message })] }), _jsxs(FormControl, { fullWidth: true, error: Boolean(errors.password), disabled: isLoading, children: [_jsx(FormLabel, { children: "Password" }), _jsx(Controller, { name: "password", control: control, render: ({ field }) => _jsx(TextField, { fullWidth: true, ...field, type: "password", autoComplete: "current-password" }) }), _jsx(FormHelperText, { children: errors.password?.message })] })] }) }), _jsx(LoadingButton, { loading: isLoading, sx: { mt: 5, width: '100%' }, variant: "contained", type: "submit", form: "loginForm", disabled: isLoading, children: "Login" }), _jsxs(Box, { color: "error.main", py: 3, px: 1, children: [isError && (_jsx(Typography, { variant: "body1", component: "h3", children: `Error occurred: ${getQueryErrorMessage(error)}` })), data?.error && (_jsx(Typography, { variant: "body1", component: "h3", children: `Error occurred: ${data?.error}` }))] })] }) })] }));
};
export { Login };
