import { shouldNotForwardProps } from 'shared/helpers';
import { styled } from '@mui/material';
/**
 * Container that fills parent width and partially height, with content (text or spinner) centered horizontally and vertically.
 *
 * May fill only a part of container's height.
 */
export const CenteredText = styled('div', shouldNotForwardProps('fillParentHeight'))(({ theme, fillParentHeight }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: `0 0 ${fillParentHeight ?? 66.66}%`,
    fontSize: theme.typography.h5.fontSize,
    color: theme.palette.text.muted,
}));
