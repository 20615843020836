import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MenuItem } from 'core/ui';
import { SelectStyled } from './StockDisplayTypeSelect.styled';
import { StockDisplayType } from 'shared/modules/stock/models';
import { t } from 'shared/translations';
const StockDisplayTypeSelect = ({ value, onChange }) => {
    return (
    // TODO: fix this `as` later, may be MUI will fix their `styled` function to be ok with generics.
    _jsxs(SelectStyled, { value: value ?? StockDisplayType.List, onChange: onChange, children: [_jsx(MenuItem, { value: StockDisplayType.List, children: t('display.as.list') }, StockDisplayType.List), _jsx(MenuItem, { value: StockDisplayType.GroupByCells, children: t('display.by.cells') }, StockDisplayType.GroupByCells)] }));
};
export { StockDisplayTypeSelect };
